import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import "./index.css";

import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import Login from "./pages/login/Login";
import Signout from "./pages/signout/Signout";
import PrivateRoute from "./context/PrivateRoute";
import { Component as OneShot } from "./pages/oneshot/OneShot";

initializeIcons();

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />, 
        children: [
            {
                index: true,
                element: <PrivateRoute><Chat /></PrivateRoute> 
            },
            {
                path: "qa",
                lazy: () => import("./pages/oneshot/OneShot"),
                element: <PrivateRoute><OneShot /></PrivateRoute>
            },
            {
                path: "*",
                lazy: () => import("./pages/NoPage")
            }
        ]
    },
    {
        path: "/login",
        element: <Login />, 
    },
    {
        path: "/signout",
        element: <Signout />, 
    },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
            <RouterProvider router={router} />
    </React.StrictMode>
);
