import { parseThoughtsItem } from "./ThoughtsContainerParser";
import { Thought } from "../../../api";

import styles from "./ThoughtsContainer.module.css";

interface Props {
    thoughtArray: Thought[];
}

export const ThoughtsContainer = ({ thoughtArray }: Props) => {
    return (
        <ul className={styles.supportingContentNavList}>
            {thoughtArray.map((step: Thought, index: number) => {
                const parsed = parseThoughtsItem(step, index);

                return (
                    <li className={styles.supportingContentItem}>
                        <h4 className={styles.supportingContentItemHeader}>{parsed.title}</h4>
                        {parsed.contentElements.map((elem, idx) => (
                            <p key={idx}><b>{elem.key}:</b> {elem.value}</p>
                        ))}                    </li>
                );
            })}
        </ul>
    );
};
