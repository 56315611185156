export const msalConfig = {
    auth: {
        clientId: "4772f388-c7a7-4f2d-8335-ed1f973ff56d",
        authority: "https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c",
        redirectUri: "https://growbot.ey.net/",
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false, 
    }
};

export const loginRequest = {
    scopes: ["User.ReadBasic.All"]
};

