import React, { useEffect, useState } from 'react';
import { Outlet, NavLink, Link } from 'react-router-dom';
import logo from '../../assets/EY_Logo_Beam_Tag_Horizontal_RGB_White_Yellow.png';
import userlogo from '../../assets/user_icon.png';
import { clearChat } from '../chat/Chat';
import styles from './Layout.module.css';

interface User {
    displayName: string;
}

const Layout: React.FC = () => {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const fetchUserInfo = async () => {
            const accessToken = localStorage.getItem('access_token');
            if (accessToken) {
                const response = await fetch('https://graph.microsoft.com/v1.0/me', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                const data = await response.json();
                setUser(data);
            }
        };

        fetchUserInfo();
    }, []);

    return (
        <div className={styles.layout}>
            <header className={styles.header} role="banner">
                <div className={styles.headerContainer}>
                    <div>
                        <a href="/" title="EY AT Internal Chatbot" className={styles.logoLink}>
                            <img src={logo} alt="EY logo" aria-label="Link to EY website" className={styles.Logo} />
                        </a>
                    </div>
                    <div>
                        <h2 onClick={clearChat} className={styles.headerTitle}>EY GrowBot</h2>
                    </div>
                    <div>
                        <nav>
                            <ul className={styles.headerNavList}>
                                <li>
                                    {user ? (
                                        <div className={styles.userContainer}>
                                            <span className={styles.userName}>{user.displayName}</span>
                                        </div>
                                    ) : (
                                        <span>Loading user info...</span>
                                    )}
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
            <Outlet />
        </div>
    );
};

export default Layout;
