import React, { ReactNode, useEffect, useState  } from "react";
import { Navigate } from "react-router-dom";

interface PrivateRouteProps {
    children: ReactNode; 
}

export const loginRequest = {
    scopes: ["User.ReadBasic.All"]
};

const clientId = '4772f388-c7a7-4f2d-8335-ed1f973ff56d';
const redirectUri = 'https://growbot.ey.net/';
const tenantId = '5b973f99-77df-4beb-b27d-aa0c70b8482c';
const authEndpoint = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize`;
const responseType = 'token';
const state = 'YOUR_UNIQUE_STATE';
const prompt = 'select_account';

const handleLogin = () => {
    const authUrl = `${authEndpoint}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(loginRequest.scopes.join(' '))}&state=${state}&prompt=${prompt}`;
    window.location.href = authUrl;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        const getAccessTokenFromUrl = (): string | null => {
            const hash = window.location.hash.substring(1);
            const params = new URLSearchParams(hash);
            return params.get('access_token');
        };

        const accessToken = getAccessTokenFromUrl();
        if (accessToken) {
            console.log('Access Token:', accessToken);
            // Store the access token in local storage or a state management solution
            localStorage.setItem('access_token', accessToken);
            setIsAuthenticated(true);

            // Remove the access token from the URL
            window.history.replaceState(null, '', window.location.pathname);
        } else {
            handleLogin();
        }
    }, []);

    if (isAuthenticated) {
        return <>{children}</>;
    } else {
        return <div>Redirecting to login...</div>; // Optionally, provide some feedback while redirecting
    }
};

export default PrivateRoute;