import { Example } from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

 

interface Props {
    onExampleClicked: (value: string) => void;
    mode: string;
}


export const ExampleList = ({ onExampleClicked, mode }: Props) => {
    let EXAMPLES: ExampleModel[]
    if (mode=='0'){
        EXAMPLES = [
            {
                text: "Was ist der Scope vom AI Act?",
                value: "Was ist der Scope vom AI Act?"
            },
        
            {   text: "An welche Person wende ich mich am besten für das Thema Hyperautomation bei EY?", 
                value: "An welche Person wende ich mich am besten für das Thema Hyperautomation bei EY?" 
            },
        
            {   text: "Ich brauche eine Beispielhafte Agenda für einen Process Mining Workshop.", 
                value: "Ich brauche eine Beispielhafte Agenda für einen Process Mining Workshop." 
            }
        ];
    } else {
        EXAMPLES = [
            {
                text: "Stichwortbeispiel 1",
                value: "Stichwortbeispiel 1"
            },
        
            {   text: "Stichwortbeispiel 2", 
                value: "Stichwortbeispiel 2" 
            },
        
            {   text: "Stichwortbeispiel 3", 
                value: "Stichwortbeispiel 3" 
            }
        ];
    }
    return (
        <ul className={styles.examplesNavList}>
            {EXAMPLES.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
