import React, { useRef, useState, useEffect } from "react";
import {
    DefaultButton,
    Modal,
    DialogFooter,
    PrimaryButton,
    Checkbox,
    Panel,
    TextField,
    SpinButton,
    Dropdown,
    IDropdownOption,
    IconButton
} from "@fluentui/react";
import {
    SparkleFilled,
    BrainCircuitFilled,
    ChatSettings24Regular,
    Save24Regular,
    ThumbLikeDislike24Regular,
    Eye24Regular,
    BrainCircuit24Regular,
    PeopleError24Regular,
    BuildingPeople24Regular
} from "@fluentui/react-icons";

import styles from "./Chat.module.css";

import { chatApi, RetrievalMode, Approaches, AskResponse, ChatRequest, ChatTurn } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import { HelpButton } from "../../components/HelpButton";
import privacyNoticeGrowbot from "../../assets/Privacy Notice Growbot.pdf"

let clearChat: () => void;

const Chat = () => {
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [isHelpPanelOpen, setIsHelpPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(3);
    const [retrievalMode, setRetrievalMode] = useState<RetrievalMode>(RetrievalMode.Hybrid);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse][]>([]);

    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const showPopup = () => {
        setIsPopupVisible(true);
    };

    const hidePopup = () => {
        setIsPopupVisible(false);
    };

    const handleFeedback = () => {
        console.log("Feedback button clicked!");
    };

    useEffect(() => {
        showPopup();
    }, []);

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        try {
            const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer, messageId: a[1].messageId }));
            const request: ChatRequest = {
                history: [...history, { user: question, bot: undefined, messageId: undefined }],
                // approach: Approaches.ReadRetrieveRead,
                approach: Approaches.OnlyAnswerQuestion,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    retrievalMode: retrievalMode,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions,
                    suggestFollowupQuestions: useSuggestFollowupQuestions
                }
            };
            const result = await chatApi(request);
            setAnswers([...answers, [question, result]]);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    clearChat = () => {
        lastQuestionRef.current = "";
        setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onRetrievalModeChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<RetrievalMode> | undefined, index?: number | undefined) => {
        setRetrievalMode(option?.data || RetrievalMode.Hybrid);
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const onShowCitation = (citation: string, index: number) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }

        setSelectedAnswer(index);
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    const iconStyles = {
        transform: "scale(1.5)",
        marginRight: "8px"
    };

    return (
        <div className={styles.container}>
            {/* The Disclaimer popup without icons*/}
            {/*
            <Modal isOpen={isPopupVisible} onDismiss={hidePopup} isBlocking={false} containerClassName={styles.Modalcontainer}>
                    <h2>Disclaimer und Datenschutz</h2>
                <ul style={{fontSize: "16px"}}>
                    <li>Die Chatbot Anwendung befindet sich derzeit in einer Testphase.</li>
                    <li>Der Chatbot ist nur für die interne Verwendung zur Unterstützung der fachlichen Tätigkeiten von EY Mitarbeiter:innen bestimmt. </li>
                    <li>Geben Sie keine personenbezogenen Daten von sich oder anderen Personen oder der Verschwiegenheit unterliegende Informationen dem Chatbot preis.</li>
                    <li>Die Antworten des Chatbots werden auf Basis einer Künstlichen Intelligenz gewonnen. Der Chatbot kann inkorrekte Informationen generieren.</li>
                    <li>Verlassen und berufen Sie sich daher nicht auf die Informationen des Chatbots, sondern prüfen Sie diese nach, bevor Sie sie verwenden.</li>
                    <li>Alle Konversationen werden anonym gespeichert.</li>
                    <li>Wir freuen uns über Ihr Feedback, um die Anwendung zu bewerten und zu verbessern.</li>
                    <br />
                    Danke für Ihr Verständnis und Ihre Kooperation! Ihr Feedback ist uns wichtig, um die Anwendung weiter verbessern zu können.
                </ul>
                <DialogFooter>
                    <PrimaryButton onClick={hidePopup} text="Verstanden" />
                </DialogFooter>
            </Modal>
    */}

            <Modal isOpen={isPopupVisible} onDismiss={hidePopup} isBlocking={false} containerClassName={styles.Modalcontainer}>
                <h2>Disclaimer und Datenschutz</h2>
                <ul className={styles.List} style={{ paddingLeft: "20px" }}>
                    <li className={styles.Item}>
                        <ChatSettings24Regular className={styles.disclaimerIcon} style={{ transform: "scale(0.9)", marginRight: "4px", marginLeft: "-2px" }} />{" "}
                        {/* Add margin between icon and text */}
                        Die EY Growbot-Anwendung befindet sich derzeit in einer Testphase.
                    </li>

                    <li className={styles.Item}>
                        <BuildingPeople24Regular className={styles.disclaimerIcon} style={{ transform: "scale(1.2)", marginRight: "8px" }} />{" "}
                        {/* Add margin between icon and text */}
                        Der EY Growbot ist ausschließlich für deine interne Verwendung gedacht, um dich bei deinen fachlichen Tätigkeiten bei EY zu unterstützen.
                    </li>

                    <li className={styles.Item}>
                        <PeopleError24Regular className={styles.disclaimerIcon} style={{ transform: "scale(1.2)", marginRight: "8px" }} />{" "}
                        {/* Add margin between icon and text */}
                        Bitte gib keine personenbezogenen Daten von dir oder anderen Personen oder Informationen, die der Verschwiegenheit unterliegen, an den EY Growbot weiter.
                    </li>

                    <li className={styles.Item}>
                        <BrainCircuit24Regular className={styles.disclaimerIcon} style={{ transform: "scale(1.3)", marginRight: "8px" }} />{" "}
                        {/* Add margin between icon and text */}
                        Die Antworten des EY Growbots basieren auf künstlicher Intelligenz. Es ist möglich, dass der EY Growbot inkorrekte Informationen liefert. Verlasse dich daher nicht auf die Informationen des EY Growbots und berufe dich nicht darauf, sondern prüfe sie nach, bevor du sie verwendest. 
                    </li>

                    <li className={styles.Item}>
                        <Eye24Regular className={styles.disclaimerIcon} style={{ transform: "scale(1.2)", marginRight: "8px" }} /> {/* Add margin between icon and text */}
                        Alle Konversationen werden anonym gespeichert. Zu diesem Zweck wird ein Cookie in deinem Browser hinterlegt. 
                    </li>

                    <li className={styles.Item}>
                        <Save24Regular className={styles.disclaimerIcon} style={{ transform: "scale(0.9)", marginRight: "4px", marginLeft: "-2px" }} />{" "}
                        {/* Add margin between icon and text */}
                        Wir freuen uns über dein Feedback, um die Anwendung zu bewerten und weiter zu verbessern.
                    </li>

                    <li className={styles.Item}>
                        <ThumbLikeDislike24Regular className={styles.disclaimerIcon} style={{ transform: "scale(0.9)", marginRight: "4px", marginLeft: "-2px" }} />{" "}
                        {/* Add margin between icon and text */}
                        Die Datenschutzerklärung findest du unter diesem Link:&nbsp;<a href={privacyNoticeGrowbot} target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>

                    </li>
                    {/* Add other items as needed */}
                </ul>
                <DialogFooter className={styles.DialogFooter}>
                    <PrimaryButton
                        onClick={hidePopup}
                        text="Verstanden"
                        styles={{
                            root: {
                                backgroundColor: "#FFE600",
                                color: "#000000",
                                borderColor: "#FFE600",
                                paddingRight: "20px",
                                marginBottom: "10px",
                                transform: "scale(0.9)"
                            },
                            rootHovered: {
                                backgroundColor: "#FFE600",
                                color: "#000000",
                                borderColor: "#000000"
                            }
                        }}
                    />
                </DialogFooter>
            </Modal>

            <div className={styles.commandsContainer}>
                <HelpButton className={styles.commandButton} onClick={() => setIsHelpPanelOpen(!isConfigPanelOpen)} />
                <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
                {/* <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} /> */}
            </div>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                            <BrainCircuitFilled fontSize={"120px"} primaryFill={"rgba(255, 230, 0, 1)"} aria-hidden="true" aria-label="Chat logo"/>
                            <h1 className={styles.chatEmptyStateTitle}>Stelle Fragen zu Informationen <br></br>aus der Datenbasis</h1>
                            <h2 className={styles.chatEmptyStateSubtitle}>Stellen Sie eine Frage oder probieren Sie ein Beispiel</h2>
                            <ExampleList onExampleClicked={onExampleClicked} mode="0"/>
                        </div>
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {answers.map((answer, index) => (
                                <div key={index}>
                                    <UserChatMessage message={answer[0]} />
                                    <div className={styles.chatMessageGpt}>
                                        <Answer
                                            key={index}
                                            answer={answer[1]}
                                            isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                            onCitationClicked={c => {
                                                window.open(c, '_blank');
                                            }}
                                            onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                            onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                            onFollowupQuestionClicked={q => makeApiRequest(q)}
                                            onFeedbackClicked={handleFeedback}
                                            showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                        />
                                    </div>
                                </div>
                            ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    <div className={styles.chatInput}>
                        <QuestionInput
                            clearOnSend
                            placeholder="Stellen Sie eine neue Frage."
                            disabled={isLoading}
                            onSend={question => makeApiRequest(question)}
                        />
                    </div>
                </div>

                {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                    />
                )}

                <Panel
                    headerText="Configure answer generation"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <TextField
                        className={styles.chatSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Retrieve this many documents from search:"
                        min={1}
                        max={50}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField className={styles.chatSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticRanker}
                        label="Use semantic ranker for retrieval"
                        onChange={onUseSemanticRankerChange}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label="Suggest follow-up questions"
                        onChange={onUseSuggestFollowupQuestionsChange}
                    />
                    <Dropdown
                        className={styles.chatSettingsSeparator}
                        label="Retrieval mode"
                        options={[
                            { key: "hybrid", text: "Vectors + Text (Hybrid)", selected: retrievalMode == RetrievalMode.Hybrid, data: RetrievalMode.Hybrid },
                            { key: "vectors", text: "Vectors", selected: retrievalMode == RetrievalMode.Vectors, data: RetrievalMode.Vectors },
                            { key: "text", text: "Text", selected: retrievalMode == RetrievalMode.Text, data: RetrievalMode.Text }
                        ]}
                        required
                        onChange={onRetrievalModeChange}
                    />
                </Panel>
                {/* Help Panel */}
                <Panel
                    headerText="Hilfe und Tipps für die Benutzung"
                    isOpen={isHelpPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsHelpPanelOpen(false)}
                    closeButtonAriaLabel="SChließen"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsHelpPanelOpen(false)}>Schließen</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>

                            {/* 
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <IconButton
                                    style={{ color: "black" }}
                                    iconProps={{ iconName: "ReportHacked" }}
                                    title="Antwort melden"
                                    ariaLabel="Antwort melden"
                                />
                                <p style={{ marginLeft: "10px" }}>
                                    Drücken Sie den Melden Knopf um eine Antwort zu melden falls diese beleidigend, rassistisch oder anderwärtig unpassend ist.
                                </p>
                            </div>
                            
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <IconButton style={{ color: "black" }} iconProps={{ iconName: "Feedback" }} title="Feedback" ariaLabel="Feedback" />
                                <p style={{ marginLeft: "10px" }}>
                                    Drücken Sie den Feedback Knopf um Feedback für die Antwort zu hinterlassen. Wählen Sie eine der Optionen und schicken Sie Ihr Feedback mit dem Absenden Knopf ab.
                                </p>
                            </div>
                            */}
                        </div>
                    </div>
                    <p>Willkommen beim EY Growbot! Hier findest du einige Tipps zur Nutzung:</p>
                    <ul>
                        <li>Der EY Growbot ist ein Chatbot, der dazu dient auf Basis unserer internen Daten bei EY Österreich, Informationen abzufragen. Auf gewohnter Weise, wie mit bisherigen Chatbots, kann das allgemeine Wissen des Chatbots mit unseren Daten auf vielfältige Weise kombiniert werden.</li>
                        <li>Die Antworten sind immer mit Links versehen, die auf die Dokumente in der Knowledge Base verweisen und als Quelle dienen um die Nachvollziehbarkeit der Antwort zu erhöhen. </li>
                       
                    </ul>
                    <p>Hier sind einige allgemeine Tipps zur Nutzung des Chatbots:</p>
                    <ul>
                    <li>Um qualitätsvolle Antworten zu gewährleisten, stelle deine Anfragen so spezifisch wie möglich. Je genauer deine Anfrage ist, desto besser kann der EY Growbot dir helfen. Vermeide Fragen, die offen, subjektiv oder wertend sind. Mit solchen Fragen kann der Chatbot nicht gut umgehen.</li>
                        <li>Sollten deine Anfragen dennoch nicht auf befriedigender Weise beantwortet werden, kann das an einer fehlenden Datenbasis liegen. Kontaktiere in diesem Fall bitte den Content Champion deines Teams um deine Daten in die Knowledge Base einzuspielen. </li>
                    </ul>
                    <p>Ist bisher noch kein Content Champion definiert bzw. bekannt, kannst du dich auch beim EY Growbot Governance Team (Benjamin Kalusa, Can Özkan) melden.</p>
                    <p>Wir hoffen, dass diese Tipps helfen, den Chatbot optimal nutzen zu können.</p>
                </Panel>
            </div>
        </div>
    );
};

export { clearChat };
export default Chat;
