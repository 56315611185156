import { Thought } from "../../../api";


type ParsedThoughtItem = {
    title: string;
    contentElements: { key: string; value: string }[]; // Array von Objekten mit Schlüssel-Wert-Paaren

};

export function parseThoughtsItem(item: Thought, index: number): ParsedThoughtItem {
    const titleWithStep = `Step ${index + 1}: ${item.title}`;

    const contentElements: { key: string; value: string }[] = [];

    if ('intent' in item) {
        // Es ist ein UnderstandingThought
        contentElements.push({ key: 'Thought Process', value: item.thought_process });
        contentElements.push({ key: 'Reasoning', value: item.reasoning });
        contentElements.push({ key: 'Language', value: item.language });
        contentElements.push({ key: 'Intent', value: item.intent });
    } else if ('query' in item) {
        // Es ist ein RetrievalThought
        contentElements.push({ key: 'Query', value: item.query });
        contentElements.push({ key: 'Retrieved Docs', value: item.retrieved_docs.join(', ') });
    } else if ('final_answer' in item) {
        // Es ist ein AnswerRAGThought
        contentElements.push({ key: 'Thought Process', value: item.thought_process });
        contentElements.push({ key: 'Reasoning', value: item.reasoning });
        contentElements.push({ key: 'Final Answer', value: item.final_answer });
    }

    return {
        title: titleWithStep,
        contentElements
    };
}

