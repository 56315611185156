// src/pages/signout/Signout.tsx
import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

const Signout: React.FC = () => {
    const { instance } = useMsal();

    useEffect(() => {
        instance.logoutRedirect().catch(e => {
            console.error(e);
        });
    }, [instance]);

    return <div>Signing out...</div>;
};

export default Signout;
