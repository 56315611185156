import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../../config/authConfig";

const Login: React.FC = () => {
    const { instance, accounts } = useMsal();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Accounts after login: ", accounts);
        console.log("Session Storage: ", sessionStorage);
        if (accounts.length > 0) {
            navigate("/");
        }
    }, [accounts, navigate]);

    const handleLogin = async () => {
        try {
            console.log("Attempting to login with redirect...");
            await instance.loginRedirect(loginRequest);
        } catch (e) {
            console.error("Login error: ", e);
        }
    };

    return (
        <div>
            <button onClick={handleLogin}>Login with Microsoft</button>
        </div>
    );
};

export default Login;
