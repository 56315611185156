import { useMemo, useState } from "react";
import { Stack, IconButton, TextField, DefaultButton } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import { AskResponse, getCitationFilePath, reportApi, feedbackApi } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";

interface Props {
    answer: AskResponse;
    isSelected?: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    onFeedbackClicked?: () => void;
    oneShot?: boolean;
}

export const Answer = ({
    answer,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    onFeedbackClicked,
    oneShot
}: Props) => {
    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer.answer, onCitationClicked, oneShot), [answer]);
    const messageId = answer.messageId;
    // let sanitizedAnswerHtml: string | Element = DOMPurify.sanitize(parsedAnswer.answerHtml);
    let sanitizedAnswerHtml: string | Element = parsedAnswer.answerHtml;

    const [showPopup, setShowPopup] = useState(false);
    const [showPopupReport, setShowPopupReport] = useState(false);
    const [feedbackText, setFeedbackText] = useState("");
    const [ReportText, setReportText] = useState("");
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [selectedLinkOption, setSelectedLinkOption] = useState<string | null>(null);
    const [selectedReportOption, setSelectedReportOption] = useState<string | null>(null);
    const [isIconClicked, setIsIconClicked] = useState(false);
    const [isAnswerReported, setIsAnswerReported] = useState(false);

    const genericHandleButtonClick = (option: string, selectedOption: string | null, setterFct: Function) => {
        if (selectedOption === option) {
            setterFct(null); // Deselect the button if it's already selected
        } else {
            setterFct(option); // Select the button if it's not selected
        }
    };

    // if (!onFeedbackClicked) {
    //     const onFeedbackClicked = () => {
    //         console.log("Feedback button clicked!");
    //     };
    // }
    // onCitationClicked('test')
    const onReportClicked = () => {
        if (!isAnswerReported) {
            setIsIconClicked(!isIconClicked);
        }
    };
    const iconLabel = isIconClicked ? "Antwort gemeldet" : "Antwort melden";

    const iconStyles = {
        color: isIconClicked ? "rgba(230, 35, 41, 1)" : "black",
        transform: isIconClicked ? "scale(1.5)" : "scale(1)"
    };

    const arrangeButtons = (buttonOptions: Array<any>, selectedOption: string | null, setterFct: Function) => {
        const buttonArray = [];
        for (let i = 0; i < buttonOptions.length; i++) {
            buttonArray.push(
                <DefaultButton
                    text={buttonOptions[i].text}
                    onClick={() => genericHandleButtonClick(buttonOptions[i].text, selectedOption, setterFct)}
                    styles={
                        selectedOption === buttonOptions[i].text
                            ? {
                                  root: { backgroundColor: "#FFE600", color: "#000000" },
                                  rootHovered: { backgroundColor: "#FFE600", color: "#000000", outline: "2px solid rgba(0, 0, 0, 1)"}
                              }
                            : undefined
                    }
                />
            );
        }
        return buttonArray;
    };

    const extractFilename = (url: string) => {
        if (!url || typeof url !== 'string' || url.includes('None')) {
            return null;  // Frühzeitiges Zurückgeben von null, wenn die URL ungültig ist oder 'None' enthält
          }
          
          try {
            const urlObj = new URL(url);
            const pathname = urlObj.pathname;
            const filename = pathname.split('/').pop();
            if (filename){
                return decodeURIComponent(filename);
            }
            return null
            
          } catch (e) {
            console.error('Invalid URL', e);
            return null;
        }
    };

    const ReportOptions = [
        { name: "ReportOption 1", text: "Die Antwort ist rassistisch" },
        { name: "ReportOption 2", text: "Die Antwort ist sexistisch" },
        { name: "ReportOption 3", text: "Die Antwort ist auf andere Weise diskriminierend" },
        { name: "ReportOption 4", text: "Ich finde die Antwort ist beleidigend" }
    ];
    const ReportButtons = arrangeButtons(ReportOptions, selectedReportOption, setSelectedReportOption);
    const linkOptions = [
        { name: "LinkOption 1", text: "Die angeführten Links erläutern die Antwort näher" },
        { name: "LinkOption 2", text: "Die angeführten Links erläutern die Antwort nicht näher" }
    ];
    const linkButtons = arrangeButtons(linkOptions, selectedLinkOption, setSelectedLinkOption);

    const feedbackOptions = [
        { name: "Option 1", text: "Frage wird richtig und umfassend beantwortet" },
        { name: "Option 2", text: "Frage wird richtig und zu oberflächlich beantwortet" },
        { name: "Option 3", text: "Frage wird teilweise richtig beantwortet" },
        { name: "Option 4", text: "Frage wird definitiv falsch beantwortet" },
        { name: "Option 5", text: "Antwort ist korrekt, passt aber nicht zur Frage" }
    ];
    const feedbackButtons = arrangeButtons(feedbackOptions, selectedOption, setSelectedOption);
    let answer_list: any[] = [];
    let citations: any[] = [];
    console.log(parsedAnswer)
    if (oneShot) {
            parsedAnswer.fragments.map((part, index) => {
            if (index % 2 === 0) {
                answer_list.push(<div className={styles.answerText} dangerouslySetInnerHTML={{ __html: part }}></div>);
            } else {
                const path = getCitationFilePath(part);
                let citationIndex: number;
                if (citations.indexOf(part) !== -1) {
                    citationIndex = citations.indexOf(part) + 1;
                } else {
                    // parsedAnswer.citations.push(part);
                    citationIndex = citations.length;
                }
                console.log(citationIndex)
                answer_list.push(        
                    <a key={citationIndex} className={styles.citation} title={part} onClick={() => onCitationClicked(path)}>
                        {part}
                    </a>
                )
            }
        })
    } else {
        answer_list = [<div className={styles.answerText} dangerouslySetInnerHTML={{ __html: parsedAnswer.answerHtml }}></div>]
    }
    console.log(answer_list);

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <AnswerIcon />
                    <div>

                        {/*Uncomment the Citations button
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Zeige den Denkprozess"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.data_points.length}
                        />
                        
                        <IconButton
                            style={iconStyles}
                            iconProps={{ iconName: "ReportHacked" }}
                            title={iconLabel}
                            ariaLabel={iconLabel}
                            onClick={() => {
                                onReportClicked();
                                if (showPopupReport) {
                                    setShowPopupReport(false);
                                } else {
                                    setShowPopup(false);
                                    setShowPopupReport(true);
                                }
                                console.log("showpopupReport: ", showPopupReport);
                            }}
                        />
                        */}
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                {/* <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div> */}
                <div className={styles.answerText}>
                    {answer_list}
                </div>

            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Quellen:<br></br></span>
                        {parsedAnswer.citations.map((url, i) => {
                        const filename = extractFilename(url);
                        if (!filename) {
                            return null; // Überspringe diesen Eintrag, wenn kein gültiger Dateiname vorhanden ist
                        }
                        const path = getCitationFilePath(url);
                        return (
                            <a key={i} className={styles.citation} title={url} onClick={() => onCitationClicked(path)}>
                            {`${filename}`} 
                            </a>
                        );
                        })}
                    </Stack>
                    </Stack.Item>

            )}

            {!!parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {parsedAnswer.followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {/* Add the feedback button 
            <div>
                <IconButton
                    style={{ color: "black" }}
                    iconProps={{ iconName: "Feedback" }}
                    title="Feedback"
                    ariaLabel="Feedback"
                    // New event handlers for hovering over the feedback button
                    onClick={() => {
                        if (showPopup) {
                            setShowPopup(false);
                        } else {
                            if (showPopupReport) {
                                onReportClicked();
                            }
                            setShowPopupReport(false);
                            setShowPopup(true);
                        }
                        console.log("showpopup: ", showPopup);
                    }}
                />
            </div>
            */}
            {showPopupReport && (
                <div className={styles.feedbackPopup}>
                    <h3>Antwort melden</h3>
                    <p>Bitte wählen Sie eine passende Option:</p>
                    <Stack.Item>
                        {/* Buttons for different options */}
                        <Stack tokens={{ childrenGap: 10 }} verticalAlign="center">
                            {ReportButtons}
                        </Stack>
                    </Stack.Item>
                    <p>Platz für zusätzliche Anmerkungen:</p>
                    <TextField multiline value={ReportText} onChange={(ev, newValue) => setReportText(newValue || "")} />
                    <div className={styles.submitButtonContainer}>
                        <DefaultButton
                            text="Absenden"
                            onClick={() => {
                                // Handle the feedback submission logic here
                                // For example, you can send the feedbackText to your backend
                                // to process the feedback and close the popup after submitting.
                                // For this example, we will simply close the popup after submitting.
                                setShowPopupReport(false);
                                setIsAnswerReported(true);
                                setIsIconClicked(true);
                                const reportStatus = reportApi(messageId, selectedReportOption, ReportText);
                                // console.log("ReportText: ", ReportText);
                                setSelectedReportOption(null);
                                setReportText(""); // Clear the text field after submitting
                            }}
                        />
                    </div>
                </div>
            )}
            {showPopup && (
                <div className={styles.feedbackPopup}>
                    <h3>Feedback</h3>
                    <p>Bitte wählen Sie eine passende Option:</p>
                    <Stack.Item>
                        {/* Buttons for different options */}
                        <Stack tokens={{ childrenGap: 10 }} verticalAlign="center">
                            {feedbackButtons}
                        </Stack>
                    </Stack.Item>
                    {/* <p>Platz für zusätzliche Anmerkungen:</p>
                    <TextField multiline value={feedbackText} onChange={(ev, newValue) => setFeedbackText(newValue || "")} /> */}
                    <div className={styles.submitButtonContainer}>
                        <DefaultButton
                            text="Absenden"
                            onClick={() => {
                                // Handle the feedback submission logic here
                                // For example, you can send the feedbackText to your backend
                                // to process the feedback and close the popup after submitting.
                                // For this example, we will simply close the popup after submitting.
                                setShowPopup(false);
                                const feedbackResponse = feedbackApi(messageId, selectedOption, selectedLinkOption, feedbackText);
                                // console.log("FeedbackText: ", feedbackText);
                                setSelectedOption(null);
                                setSelectedLinkOption(null);
                                setFeedbackText(""); // Clear the text field after submitting
                            }}
                        />
                    </div>
                </div>
            )}
        </Stack>
    );
};
