import { renderToStaticMarkup, renderToString } from "react-dom/server";
import { getCitationFilePath } from "../../api";
import styles from "./Answer.module.css";


type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
    followupQuestions: string[];
    fragments: any[];
};

export function parseAnswerToHtml(answer: string, onCitationClicked: (citationFilePath: string) => void, oneShot: boolean | undefined): HtmlParsedAnswer {
    let citations: string[] = [];
    const followupQuestions: string[] = [];
    // console.log(oneShot)
    // console.log(answer)
    // Extract any follow-up questions that might be in the answers
    let parsedAnswer = answer.replace(/<<([^>>]+)>>/g, (match, content) => {
        followupQuestions.push(content);
        return "";
    });

    // trim any whitespace from the end of the answer after removing follow-up questions
    parsedAnswer = parsedAnswer.trim();

    const parts = parsedAnswer.split(/\[([^\]]+)\]/g);
    let fragments: string[] = []
    if (!oneShot) {
    // if (true) {
        fragments = parts.map((part, index) => {
            console.log('1')
            if (index % 2 === 0) {
                return part;
            } else {
                let citationIndex: number;
                if (citations.indexOf(part) !== -1) {
                    citationIndex = citations.indexOf(part) + 1;
                } else {
                    citations.push(part);
                    citationIndex = citations.length;
                }
    
                const path = getCitationFilePath(part);
                
                return renderToString(
                                        
                    <a className="supContainer" title={part} onClick={() => onCitationClicked(path)}>
                        {/*<sup>{citationIndex}</sup>*/}
                    </a>
                    
                );
            }
        });
    } else {
        // console.log('2')
        fragments = parts.map((part, index) => {
            // console.log(part);
            if (index % 2 === 0) {
                return part;
            } else {
                const path = getCitationFilePath(part);
                let citationIndex: number;
                if (citations.indexOf(part) !== -1) {
                    citationIndex = citations.indexOf(part) + 1;
                } else {
                    citations.push(part);
                    citationIndex = citations.length;
                }
                console.log(citationIndex)
                console.log(renderToString(        
                    <a key={citationIndex} className={styles.citation} title={part} onClick={() => onCitationClicked(path)}>
                        {part}
                    </a>
                ))
                // return renderToStaticMarkup(                    
                //     <a key={citationIndex} className={styles.citation} title={part} onClick={() => onCitationClicked(path)}>
                //         {part}
                //     </a>
                // );
                return `${part}`
            }
        });
        citations = [];
    }
    // console.log(fragments.join(""))
    return {
        answerHtml: fragments.join(""),
        citations,
        followupQuestions,
        fragments: fragments
    };
}
