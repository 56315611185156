export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    ReadDecomposeAsk = "rda",
    OnlyAnswerQuestion = "oaq"
}

export const enum RetrievalMode {
    Hybrid = "hybrid",
    Vectors = "vectors",
    Text = "text"
}

export type AskRequestOverrides = {
    retrievalMode?: RetrievalMode;
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
};

export type AskRequest = {
    question: string;
    approach: Approaches;
    overrides?: AskRequestOverrides;
};
// export type AskSource = {
//     source: string;
//     content: string;
// };

type UnderstandingThought = {
    title: string;
    thought_process: string;
    reasoning: string;
    language: string;
    intent: string;
};

type RetrievalThought = {
    title: string;
    query: string;
    retrieved_docs: string[]; // Oder ein spezifischerer Typ, falls notwendig
};

type AnswerRAGThought = {
    title: string;
    thought_process: string;
    reasoning: string;
    final_answer: string;
};

// Union aller möglichen "Thought" Typen
export type Thought = UnderstandingThought | RetrievalThought | AnswerRAGThought;

export type AskResponse = {
    messageId: number;
    answer: string;
    thoughts: Thought[] | null; // Jetzt ein Array von Thought Objekten oder null
    data_points: string[]; // | Array<AskSource>;
    error?: string;
};

export type ChatTurn = {
    user: string;
    bot?: string;
    messageId?: number;
};

export type ChatRequest = {
    history: ChatTurn[];
    approach: Approaches;
    overrides?: AskRequestOverrides;
};
